import { Popper, PopperProps } from "@mui/material";

// This is a helper component that lets popovers in autocompletes extend passed the width of the parent.
const styles = {
  popper: {
    width: "fit-content"
  }
};

const PopperMy = function (props: PopperProps) {
  return <Popper {...props} style={styles.popper} placement="bottom-start" />;
};

export default PopperMy;
