import { useEffect, useRef, useState } from "react";
import { Box, Paper, Stack, IconButton, Tooltip } from "@mui/material";
import {
  CaptureBayAnnotation,
  CapturePositionAnnotation,
} from "../../../models/planograms/AnnotationTypes";
import ProductComparisonDialog from "./product-comparison/ProductComparison";
import { usePlanogramStore } from "../../../stores/snapshots/PlanogramStore";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

interface GridRect {
  x: number;
  y: number;
  w: number;
  h: number;
  position: CapturePositionAnnotation;
}

interface CachedImage {
  image: HTMLImageElement;
  loaded: boolean;
}

interface InteractiveBayDisplayProps {
  imageUrl: string;
  captureBayData: CaptureBayAnnotation | undefined;
  captureId: string | null;
  callUpdatedData: () => Promise<void>;
}

const BAY_CONTENT_HEIGHT = 560;
const BAY_CONTENT_ASPECT_RATIO = 0.67;

// Get device pixel ratio for high DPI displays
const getPixelRatio = (): number => {
  return window.devicePixelRatio || 1;
};

const InteractiveBayDisplay = ({
  imageUrl,
  captureBayData,
  captureId,
  callUpdatedData,
}: InteractiveBayDisplayProps) => {
  const displayCanvasRef = useRef<HTMLCanvasElement>(null);
  const bufferCanvasRef = useRef<HTMLCanvasElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [productRects, setProductRects] = useState<GridRect[]>([]);
  const [openCompareProductDialog, setOpenCompareProductDialog] =
    useState(false);
  const [hoveredProduct, setHoveredProduct] = useState<GridRect | null>(null);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [panOffset, setPanOffset] = useState({ x: 0, y: 0 });
  const isDragging = useRef(false);
  const hasDragged = useRef(false);
  const lastMousePos = useRef({ x: 0, y: 0 });
  const { setActiveCapturePositionAnnotation } = usePlanogramStore();
  const imageCache = useRef<Map<string, CachedImage>>(new Map());
  const drawRequestRef = useRef<number>();

  // Calculate dimensions based on container size
  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const container = containerRef.current;
        const containerHeight = container.clientHeight;
        const containerWidth = container.clientWidth;

        // Calculate dimensions maintaining aspect ratio
        let width = containerHeight * BAY_CONTENT_ASPECT_RATIO;
        let height = containerHeight;

        // If width exceeds container width, scale down
        if (width > containerWidth) {
          width = containerWidth;
          height = width / BAY_CONTENT_ASPECT_RATIO;
        }

        setDimensions({ width, height });
      }
    };

    updateDimensions();
    window.addEventListener("resize", updateDimensions);

    // Force a redraw after a small delay to ensure canvas is ready
    const timer = setTimeout(() => {
      requestRedraw();
    }, 100);

    return () => {
      window.removeEventListener("resize", updateDimensions);
      clearTimeout(timer);
    };
  }, []);

  // Redraw on zoom and pan changes
  useEffect(() => {
    requestRedraw();
  }, [zoomLevel, panOffset]);

  // Ensure initial draw happens after dimensions and data are set
  useEffect(() => {
    if (dimensions.width > 0 && dimensions.height > 0 && captureBayData) {
      // Small delay to ensure everything is ready
      const timer = setTimeout(() => {
        requestRedraw();
      }, 100);
      return () => clearTimeout(timer);
    }
  }, [dimensions.width, dimensions.height, captureBayData]);

  // Draw after browser resize and dims are setup
  useEffect(() => {
    if (dimensions.width > 0 && dimensions.height > 0) {
      const displayCanvas = displayCanvasRef.current;
      const bufferCanvas = bufferCanvasRef.current;
      if (!displayCanvas || !bufferCanvas) return;

      const displayCtx = displayCanvas.getContext("2d", { alpha: false });
      const bufferCtx = bufferCanvas.getContext("2d", { alpha: false });
      if (!displayCtx || !bufferCtx) return;

      setupCanvas(bufferCanvas, bufferCtx);
      setupCanvas(displayCanvas, displayCtx);
      
      // Initialize with white background
      displayCtx.fillStyle = "white";
      displayCtx.fillRect(0, 0, canvasWidth * getPixelRatio(), canvasHeight * getPixelRatio());
      bufferCtx.fillStyle = "white";
      bufferCtx.fillRect(0, 0, canvasWidth * getPixelRatio(), canvasHeight * getPixelRatio());
      
      // Force immediate draw
      drawFrame();
    }
  }, [dimensions, captureBayData, imageUrl]);

  // Calculate canvas dimensions
  const canvasWidth = dimensions.width;
  const canvasHeight = dimensions.height;

  const setupCanvas = (
    canvas: HTMLCanvasElement,
    ctx: CanvasRenderingContext2D
  ) => {
    const pixelRatio = getPixelRatio();

    // Set canvas dimensions accounting for pixel ratio
    canvas.width = canvasWidth * pixelRatio;
    canvas.height = canvasHeight * pixelRatio;

    // Set display size
    canvas.style.width = `${canvasWidth}px`;
    canvas.style.height = `${canvasHeight}px`;

    // Scale all drawing operations by the pixel ratio
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);

    // Initialize with white background
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.imageSmoothingEnabled = true;
    ctx.imageSmoothingQuality = "high";
  };

  useEffect(() => {
    return () => {
      // Cleanup animation frame on unmount
      if (drawRequestRef.current) {
        cancelAnimationFrame(drawRequestRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const displayCanvas = displayCanvasRef.current;
    const bufferCanvas = bufferCanvasRef.current;
    if (!displayCanvas || !bufferCanvas || !captureBayData) return;

    const displayCtx = displayCanvas.getContext("2d", { alpha: false });
    const bufferCtx = bufferCanvas.getContext("2d", { alpha: false });
    if (!displayCtx || !bufferCtx) return;

    setupCanvas(bufferCanvas, bufferCtx);
    setupCanvas(displayCanvas, displayCtx);

    // Track number of images to load
    let imagesToLoad = 0;
    let imagesLoaded = 0;

    // Pre-cache images if they're not already cached
    captureBayData.CaptureFixtures.forEach((fixture) => {
      fixture.CapturePositions.forEach((position) => {
        if (
          position.ProductImageUrl &&
          !imageCache.current.has(position.ProductImageUrl)
        ) {
          imagesToLoad++;
          const img = new Image();
          img.crossOrigin = "anonymous";
          img.src = `${process.env.REACT_APP_URSTORE_CONTENT_PREFIX}assets/products_thumbnail/${position.ProductImageUrl}`;
          imageCache.current.set(position.ProductImageUrl, {
            image: img,
            loaded: false,
          });

          img.onload = () => {
            if (position.ProductImageUrl) {
              const cachedImage = imageCache.current.get(
                position.ProductImageUrl
              );
              if (cachedImage) {
                cachedImage.loaded = true;
                imagesLoaded++;
                
                // Force redraw after all images are loaded
                if (imagesLoaded === imagesToLoad) {
                  // Small delay to ensure everything is ready
                  setTimeout(() => {
                    requestRedraw();
                  }, 50);
                }
              }
            }
          };
        }
      });
    });

    // Initial draw even if no images need loading
    if (imagesToLoad === 0) {
      setTimeout(() => {
        requestRedraw();
      }, 50);
    }
  }, [imageUrl, captureBayData]);

  useEffect(() => {
    requestRedraw();
  }, [hoveredProduct]);

  const getGridPosition = (
    position: CapturePositionAnnotation,
    fixtureCardinality: number
  ): GridRect => {
    return {
      x: position.Xpos * canvasWidth,
      y: position.Ypos * canvasHeight,
      w: position.Width * canvasWidth,
      h: position.Height * canvasHeight,
      position: {
        ...position,
        FixtureCardinality: fixtureCardinality,
      },
    };
  };

  const requestRedraw = () => {
    if (drawRequestRef.current) {
      cancelAnimationFrame(drawRequestRef.current);
    }
    drawRequestRef.current = requestAnimationFrame(drawFrame);
  };

  const getStatusText = (status: number): string => {
    switch (status) {
      case 0:
        return "In Stock";
      case 1:
        return "Wrong Product";
      case 2:
        return "Out of Stock";
      case 3:
        return "Other";
      default:
        return "Unknown";
    }
  };

  const getStatusColor = (status: number): string => {
    switch (status) {
      case 0:
        return "#449d48"; // In stock - success.main
      case 1:
        return "#ff9800"; // Wrong product - warning.main
      case 2:
        return "#ed3c39"; // Out of stock - error.main
      case 3:
        return "gray"; // Other
      default:
        return "blue";
    }
  };

  const drawStatusIndicator = (
    ctx: CanvasRenderingContext2D,
    rect: GridRect,
    status: number
  ) => {
    const indicatorHeight = 4;
    const indicatorWidth = rect.w * 0.8; // 80% of product width
    const x = rect.x + (rect.w - indicatorWidth) / 2;
    const y = rect.y + rect.h + 2; // 2px gap between product and indicator

    ctx.fillStyle = getStatusColor(status);
    ctx.beginPath();
    ctx.roundRect(x, y, indicatorWidth, indicatorHeight, indicatorHeight / 2); // radius = half height for pill shape
    ctx.fill();
  };

  const drawPlaceholder = (ctx: CanvasRenderingContext2D, rect: GridRect) => {
    // Create gradient
    const gradient = ctx.createLinearGradient(
      rect.x,
      rect.y,
      rect.x,
      rect.y + rect.h
    );
    gradient.addColorStop(0, "#f5f5f5");
    gradient.addColorStop(1, "#e0e0e0");

    // Draw rounded rectangle with gradient
    ctx.fillStyle = gradient;
    ctx.beginPath();
    ctx.roundRect(rect.x + 2, rect.y + 2, rect.w - 4, rect.h - 4, 4);
    ctx.fill();
  };

  /**
   * This is an absolutely cursed function that hand-draws a tooltip inside a canvas.
   * Thanks, Claude. Couldn't have figured this bit out without you.
   * @param ctx Context reference to the canvas
   * @param rect The grid rect of the product
   * @returns
   */
  const drawTooltip = (ctx: CanvasRenderingContext2D, rect: GridRect) => {
    if (!hoveredProduct) return;

    const position = rect.position;
    const status = getStatusText(position.Status);
    const name = position.ProductName || "Unknown Product";

    // Save current transform state
    ctx.save();
    
    // Reset the transform to counter the zoom effect
    ctx.scale(1 / zoomLevel, 1 / zoomLevel);

    // Adjust position to account for zoom level
    const zoomedX = rect.x * zoomLevel;
    const zoomedY = rect.y * zoomLevel;

    // Set tooltip style
    ctx.font =
      "14px -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif";
    ctx.textBaseline = "middle";

    // Calculate text dimensions
    const padding = 8;
    const dotSize = 8;
    const dotPadding = 6;
    const nameWidth = ctx.measureText(name).width;
    const statusWidth = ctx.measureText(status).width;
    const tooltipWidth =
      Math.max(nameWidth, dotSize + dotPadding + statusWidth) + padding * 2;
    const lineHeight = 20;
    const tooltipHeight = lineHeight * 2 + padding * 2;

    // Calculate tooltip position
    let tooltipX = zoomedX + (rect.w * zoomLevel) / 2 - tooltipWidth / 2;
    let tooltipY = zoomedY - tooltipHeight - 10;

    // Ensure tooltip stays within canvas bounds
    tooltipX = Math.max(0, Math.min(tooltipX, canvasWidth * zoomLevel - tooltipWidth));
    tooltipY = Math.max(0, tooltipY);

    // Draw tooltip background
    ctx.fillStyle = "rgba(0, 0, 0, 0.8)";
    ctx.beginPath();
    ctx.roundRect(tooltipX, tooltipY, tooltipWidth, tooltipHeight, 4);
    ctx.fill();

    // Draw text
    ctx.fillStyle = "white";
    ctx.fillText(name, tooltipX + padding, tooltipY + padding + lineHeight / 2);

    // Draw status dot
    const dotX = tooltipX + padding;
    const dotY = tooltipY + padding + lineHeight + lineHeight / 2;
    ctx.beginPath();
    ctx.arc(dotX + dotSize / 2, dotY, dotSize / 2, 0, Math.PI * 2);
    ctx.fillStyle = getStatusColor(position.Status);
    ctx.fill();

    // Draw status text
    ctx.fillStyle = "lightgray";
    ctx.fillText(status, dotX + dotSize + dotPadding, dotY);

    // Restore original transform state
    ctx.restore();
  };

  const handleZoomIn = () => {
    setZoomLevel(prev => Math.min(prev + 0.25, 3));
    // Force immediate draw
    drawFrame();
  };

  const handleZoomOut = () => {
    setZoomLevel(prev => Math.max(prev - 0.25, 1));
    // Force immediate draw
    drawFrame();
  };

  const handleResetZoom = () => {
    setZoomLevel(1);
    setPanOffset({ x: 0, y: 0 });
    // Force immediate draw
    drawFrame();
  };

  const handleCanvasMouseDown = (event: React.MouseEvent<HTMLCanvasElement>) => {
    isDragging.current = true;
    hasDragged.current = false;
    lastMousePos.current = { x: event.clientX, y: event.clientY };
  };

  const handleCanvasMouseUp = () => {
    isDragging.current = false;
  };

  const handleCanvasMouseMove = (event: React.MouseEvent<HTMLCanvasElement>) => {
    const canvas = displayCanvasRef.current;
    if (!canvas) return;

    if (isDragging.current) {
      const dx = event.clientX - lastMousePos.current.x;
      const dy = event.clientY - lastMousePos.current.y;
      
      // Only set hasDragged if we've moved a meaningful amount
      if (Math.abs(dx) > 3 || Math.abs(dy) > 3) {
        hasDragged.current = true;
      }
      
      setPanOffset(prev => ({
        x: prev.x + dx,
        y: prev.y + dy
      }));
      
      lastMousePos.current = { x: event.clientX, y: event.clientY };
      // Force immediate draw while dragging
      drawFrame();
      return;
    }

    const rect = canvas.getBoundingClientRect();
    const pixelRatio = getPixelRatio();

    // Adjust coordinates for zoom and pan
    const x = ((event.clientX - rect.left - panOffset.x) / zoomLevel) * (canvas.width / rect.width / pixelRatio);
    const y = ((event.clientY - rect.top - panOffset.y) / zoomLevel) * (canvas.height / rect.height / pixelRatio);

    // Find hovered product
    const hoveredRect = productRects.find(
      (productRect) =>
        x >= productRect.x &&
        x <= productRect.x + productRect.w &&
        y >= productRect.y &&
        y <= productRect.y + productRect.h
    );

    canvas.style.cursor = isDragging.current ? "grabbing" : (hoveredRect ? "pointer" : "grab");

    if (hoveredRect !== hoveredProduct) {
      setHoveredProduct(hoveredRect || null);
    }
  };

  const handleCanvasClick = (event: React.MouseEvent<HTMLCanvasElement>) => {
    // Prevent click if we've dragged
    if (isDragging.current || hasDragged.current) return;

    const canvas = displayCanvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const pixelRatio = getPixelRatio();

    // Adjust coordinates for zoom and pan
    const x = ((event.clientX - rect.left - panOffset.x) / zoomLevel) * (canvas.width / rect.width / pixelRatio);
    const y = ((event.clientY - rect.top - panOffset.y) / zoomLevel) * (canvas.height / rect.height / pixelRatio);

    const clickedProduct = productRects.find(
      (productRect) =>
        x >= productRect.x &&
        x <= productRect.x + productRect.w &&
        y >= productRect.y &&
        y <= productRect.y + productRect.h
    );

    if (clickedProduct) {
      setActiveCapturePositionAnnotation(clickedProduct.position);
      setOpenCompareProductDialog(true);
    }
  };

  const handleCanvasMouseLeave = () => {
    setHoveredProduct(null);
    if (displayCanvasRef.current) {
      displayCanvasRef.current.style.cursor = "default";
    }
  };

  const drawFrame = () => {
    const bufferCanvas = bufferCanvasRef.current;
    const displayCanvas = displayCanvasRef.current;
    if (!bufferCanvas || !displayCanvas || !captureBayData) return;

    const ctx = bufferCanvas.getContext("2d", { alpha: false });
    const displayCtx = displayCanvas.getContext("2d", { alpha: false });
    if (!ctx || !displayCtx) return;

    // Reset transform and clear both canvases with white
    ctx.setTransform(1, 0, 0, 1, 0, 0);
    displayCtx.setTransform(1, 0, 0, 1, 0, 0);
    
    ctx.fillStyle = "white";
    displayCtx.fillStyle = "white";
    
    ctx.fillRect(0, 0, bufferCanvas.width, bufferCanvas.height);
    displayCtx.fillRect(0, 0, displayCanvas.width, displayCanvas.height);

    // Apply pixel ratio scaling and zoom transform
    const pixelRatio = getPixelRatio();
    ctx.scale(pixelRatio, pixelRatio);
    
    // Apply zoom and pan
    ctx.translate(panOffset.x, panOffset.y);
    ctx.scale(zoomLevel, zoomLevel);

    const rects: GridRect[] = [];

    // Draw all products
    captureBayData.CaptureFixtures.forEach((fixture) => {
      fixture.CapturePositions.forEach((position) => {
        const rect = getGridPosition(position, fixture.FixtureCardinality);
        rects.push(rect);

        if (position.ProductImageUrl) {
          const cachedImage = imageCache.current.get(position.ProductImageUrl);
          if (cachedImage?.loaded) {
            ctx.save();

            ctx.beginPath();
            ctx.rect(rect.x, rect.y, rect.w, rect.h);
            ctx.clip();

            const scale = Math.min(
              rect.w / cachedImage.image.width,
              rect.h / cachedImage.image.height
            );

            const scaledWidth = cachedImage.image.width * scale;
            const scaledHeight = cachedImage.image.height * scale;

            const x = rect.x + (rect.w - scaledWidth) / 2;
            const y = rect.y + (rect.h - scaledHeight) / 2;

            ctx.drawImage(
              cachedImage.image,
              0,
              0,
              cachedImage.image.width,
              cachedImage.image.height,
              x,
              y,
              scaledWidth,
              scaledHeight
            );

            if (
              hoveredProduct &&
              (rect.x !== hoveredProduct.x || rect.y !== hoveredProduct.y)
            ) {
              ctx.fillStyle = "rgba(255, 255, 255, 0.45)";
              ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
            }

            ctx.restore();
          } else {
            drawPlaceholder(ctx, rect);
          }

          // Draw status indicator instead of border
          drawStatusIndicator(ctx, rect, position.Status);
        } else {
          drawPlaceholder(ctx, rect);

          if (
            hoveredProduct &&
            (rect.x !== hoveredProduct.x || rect.y !== hoveredProduct.y)
          ) {
            ctx.fillStyle = "rgba(255, 255, 255, 0.45)";
            ctx.fillRect(rect.x, rect.y, rect.w, rect.h);
          }
          // Draw status indicator
          drawStatusIndicator(ctx, rect, position.Status);
        }
      });
    });

    // Draw tooltip for hovered product
    if (hoveredProduct) {
      drawTooltip(ctx, hoveredProduct);
    }

    setProductRects(rects);
    copyBufferToDisplay();
  };

  const copyBufferToDisplay = () => {
    const displayCanvas = displayCanvasRef.current;
    const bufferCanvas = bufferCanvasRef.current;
    if (!displayCanvas || !bufferCanvas) return;

    const displayCtx = displayCanvas.getContext("2d", { alpha: false });
    if (!displayCtx) return;

    // Reset transform and clear
    displayCtx.setTransform(1, 0, 0, 1, 0, 0);
    displayCtx.clearRect(0, 0, displayCanvas.width, displayCanvas.height);

    // Apply pixel ratio scaling
    const pixelRatio = getPixelRatio();
    displayCtx.scale(pixelRatio, pixelRatio);

    displayCtx.drawImage(bufferCanvas, 0, 0, canvasWidth, canvasHeight);
  };

  return (
    <Stack
      direction="row"
      spacing={2}
      alignItems="center"
      ref={containerRef}
      sx={{
        width: "100%",
        height: "100%",
        gap: 2,
      }}
    >
      {/* Original bay image */}
      <Paper
        elevation={2}
        sx={{
          borderRadius: 3,
          overflow: "hidden",
          width: dimensions.width,
          height: dimensions.height,
          display: "flex",
          position: "relative",
        }}
      >
        <img
          alt="Original Bay"
          style={{
            width: dimensions.width,
            height: dimensions.height,
          }}
          src={imageUrl}
        />
      </Paper>

      {/* Annotated display */}
      <Paper
        elevation={2}
        sx={{
          borderRadius: 3,
          overflow: "hidden",
          width: dimensions.width,
          height: dimensions.height,
          display: "flex",
          position: "relative",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 2,
            bgcolor: "rgba(255, 255, 255, 0.8)",
            borderRadius: 1,
            p: 0.5,
            display: "flex",
            gap: 0.5,
          }}
        >
          <Tooltip title="Zoom Out">
            <IconButton size="small" onClick={handleZoomOut} disabled={zoomLevel <= 1}>
              <RemoveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Reset">
            <IconButton size="small" onClick={handleResetZoom} disabled={zoomLevel === 1 && panOffset.x === 0 && panOffset.y === 0}>
              <RestartAltIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Zoom In">
            <IconButton size="small" onClick={handleZoomIn} disabled={zoomLevel >= 3}>
              <AddIcon />
            </IconButton>
          </Tooltip>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            maxWidth: dimensions.width,
            position: "relative",
            overflow: "hidden",
          }}
        >
          <canvas ref={bufferCanvasRef} style={{ display: "none" }} />
          <canvas
            ref={displayCanvasRef}
            onClick={handleCanvasClick}
            onMouseDown={handleCanvasMouseDown}
            onMouseUp={handleCanvasMouseUp}
            onMouseMove={handleCanvasMouseMove}
            onMouseLeave={handleCanvasMouseLeave}
            style={{
              width: dimensions.width,
              height: dimensions.height,
              objectFit: "contain",
              cursor: "grab",
            }}
          />
          {captureId && (
            <ProductComparisonDialog
              openCompareProductComparisonDialog={openCompareProductDialog}
              closeCompareProductComparisonDialog={() =>
                setOpenCompareProductDialog(false)
              }
              mainProductImage={imageUrl}
              captureId={captureId}
              bayIndex={captureBayData?.BayIndex ?? 0}
              fixtureCardinality={
                captureBayData?.CaptureFixtures[0]?.FixtureCardinality ?? 0
              }
              callUpdatedData={callUpdatedData}
            />
          )}
        </Box>
      </Paper>
    </Stack>
  );
};

export default InteractiveBayDisplay;
