import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Tooltip,
} from "@mui/material";
import {
  CaptureBayAnnotation,
  CapturePositionAnnotation,
} from "../../../models/planograms/AnnotationTypes";
import {
  Close,
  Gesture,
  PhotoSizeSelectLargeRounded,
  SelectAll,
} from "@mui/icons-material";

interface GridRect {
  x: number;
  y: number;
  w: number;
  h: number;
}

interface CaptureBayItemCroppingDialogProp {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
  captureBayData: CaptureBayAnnotation | undefined;
}

const CaptureBayItemCroppingDialog: React.FC<
  CaptureBayItemCroppingDialogProp
> = ({ open, onClose, imageUrl, captureBayData }) => {
  const BAY_CONTENT_ASPECT_RATIO: number = 0.67;

  const [selectedMode, setSelectedMode] = useState<string>();
  const [viewportHeight, setViewportHeight] = useState<number>(
    window.innerHeight - 132
  );
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const handleResize = () => setViewportHeight(window.innerHeight - 132);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    setSelectedMode("");
  }, [open]);

  const drawRects = (drawMode: string) => {
    const canvasEle = canvasRef.current;
    if (!canvasEle) return;

    canvasEle.width = canvasEle.clientWidth;
    canvasEle.height = canvasEle.clientHeight;
    // get context of the canvas
    const ctx = canvasEle.getContext("2d");
    if (!ctx) return;

    captureBayData?.CaptureFixtures.forEach((rowFixture) => {
      rowFixture.CapturePositions.forEach((position, index) => {
        // If in composite or detection mode, draw ML boxes where possible.
        const cRect =
          ["composite", "detection"].includes(drawMode) &&
          position.MlBoxHeight !== null &&
          getMLPosition(
            position,
            viewportHeight * BAY_CONTENT_ASPECT_RATIO,
            viewportHeight
          );

        // Draw mode. Ternaries are fun.
        const rect =
          drawMode === "composite"
            ? getGridPosition(
                position,
                viewportHeight * BAY_CONTENT_ASPECT_RATIO,
                viewportHeight
              )
            : position.MlBoxHeight !== null && drawMode !== "detection"
            ? getMLPosition(
                position,
                viewportHeight * BAY_CONTENT_ASPECT_RATIO,
                viewportHeight
              )
            : getGridPosition(
                position,
                viewportHeight * BAY_CONTENT_ASPECT_RATIO,
                viewportHeight
              );

        if (position.Status === 2 && position.MlBoxHeight !== null) {
          console.log(position);
        }

        // Product rect(s)
        drawRect(
          ctx,
          rect.x,
          rect.y,
          rect.w,
          rect.h,
          position.Status,
          drawMode,
          Boolean(position.MlBoxHeight),
          position,
          index
        );

        if (cRect) {
          drawRect(
            ctx,
            cRect.x,
            cRect.y,
            cRect.w,
            cRect.h,
            position.Status,
            "positionType",
            Boolean(position.MlBoxHeight),
            position,
            index,
            drawMode === "detection"
          );
        }
      });
    });
  };

  // draw a rectangle on canvas
  const drawRect = (
    ctx: CanvasRenderingContext2D,
    x: number,
    y: number,
    w: number,
    h: number,
    status: number,
    drawMode: string,
    hasMLposition: boolean,
    position: CapturePositionAnnotation,
    index: number,
    hideFill: boolean = false
  ) => {
    if (!ctx) return;
    let colour = "red";
    // Default tinting (status)
    switch (status) {
      case 0:
        colour = "#90EE90";
        break;
      case 1:
        colour = "orange";
        break;
      case 2:
        colour = "red";
        break;
      case 3:
        colour = "gray";
        break;
    }

    if (drawMode === "positionType") {
      if (hasMLposition) {
        colour = "#90EE90";
      } else {
        if (position.FoundUpc === null) {
          colour = "purple";
        }
        colour = "blue";
      }
    }

    if (drawMode === "composite") {
      if (!hasMLposition) {
        colour = "blue";
        if (position.FoundUpc === null) {
          colour = "deeppink";
        }
      } else {
        colour = "blue";
      }
    }

    if (drawMode === "detection") {
      colour = "orange";
    }

    console.log("rect");
    ctx.beginPath();

    ctx.strokeStyle = colour;
    ctx.lineWidth = 2;

    if (drawMode === "detection") {
      ctx.fillStyle = "rgba(255,165,64,0.7)";
    } else {
      ctx.fillStyle = "rgba(255,255,255,0.3)";
    }

    !hideFill && ctx.fillRect(x, y, w, h);

    ctx.rect(x, y, w, h);

    colour !== "orange" && ctx.stroke();
  };

  // Note: Your MlBoxWidth/Height are assumed to already exist when you call this.
  const getMLPosition = (
    capturePosition: CapturePositionAnnotation,
    imageWidth: number,
    imageHeight: number
  ): GridRect => {
    const sourceX =
      (capturePosition.MlBoxX! - capturePosition.MlBoxWidth! / 2) * imageWidth;
    const sourceY =
      (capturePosition.MlBoxY! - capturePosition.MlBoxHeight! / 2) *
      imageHeight;
    const sourceWidth = capturePosition.MlBoxWidth! * imageWidth;
    const sourceHeight = capturePosition.MlBoxHeight! * imageHeight;

    return { x: sourceX, y: sourceY, w: sourceWidth, h: sourceHeight };
  };

  const getGridPosition = (
    capturePosition: CapturePositionAnnotation,
    imageWidth: number,
    imageHeight: number
  ): GridRect => {
    // Position
    const sourceX = capturePosition.Xpos * imageWidth;
    const sourceY = capturePosition.Ypos * imageHeight;

    // Height and Width
    const sourceWidth = capturePosition.Width * imageWidth;
    const sourceHeight = capturePosition.Height * imageHeight;

    return { x: sourceX, y: sourceY, w: sourceWidth, h: sourceHeight };
  };

  return (
    <Dialog open={open} onClose={() => onClose()} fullScreen>
      <DialogTitle>
        <Stack
          direction="row"
          sx={{ width: "100%" }}
          justifyContent="space-between"
        >
          <>Capture Region Debug</>
          <Button startIcon={<Close />} color="error" onClick={() => onClose()}>
            Close
          </Button>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box
          display="flex"
          justifyContent={"center"}
          // alignItems="center"
          width="100%"
          height="100%"
        >
          <Box
            sx={{
              width: viewportHeight * BAY_CONTENT_ASPECT_RATIO,
              height: viewportHeight,
            }}
          >
            <img
              alt="Bay"
              style={{
                width: viewportHeight * BAY_CONTENT_ASPECT_RATIO,
                height: viewportHeight,
                objectFit: "fill",
                padding: 1,
                borderRadius: "6px",
              }}
              src={imageUrl}
            />
            <Box position="absolute" zIndex={9999} top={64}>
              <canvas
                key={"key-" + imageUrl}
                ref={canvasRef}
                style={{
                  width: viewportHeight * BAY_CONTENT_ASPECT_RATIO,
                  height: viewportHeight,
                  objectFit: "fill",
                  padding: 1,
                }}
              ></canvas>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        {/* SKU STATUS TEMPORARILY HIDDEN. */}
        {/* <Tooltip
          title={
            <>
              Shows <b>Preferred Position</b> rectangles, and colours by{" "}
              <b>
                SKU Status (<span style={{ color: "red" }}>Out Of Stock, </span>
                <span style={{ color: "orange" }}>Wrong Product, </span>
                <span style={{ color: "lightgreen" }}>In Stock. </span> )
              </b>
              .
            </>
          }
        >
          <Button
            startIcon={<Polyline />}
            variant="outlined"
            onClick={() => drawRects("skuStatus")}
            autoFocus
          >
            SKU Status
          </Button>
        </Tooltip> */}

        <Tooltip
          title={
            <>
              Draws rectangles based on whether a SKU is using{" "}
              <b>
                Grid Position <span style={{ color: "blue" }}>(Blue)</span>
              </b>{" "}
              or{" "}
              <b>
                ML Box Position{" "}
                <span style={{ color: "lightgreen" }}>(Green)</span>
              </b>
              . SKUs will prefer to use <b>ML Box Position</b> when available.{" "}
            </>
          }
        >
          <Button
            startIcon={<Gesture />}
            variant={selectedMode === "positionType" ? "contained" : "outlined"}
            onClick={() => {
              drawRects("positionType");
              setSelectedMode("positionType");
            }}
            autoFocus
          >
            Preferred Position
          </Button>
        </Tooltip>

        <Tooltip
          title={
            <>
              Shows all capture positions including both Grid and ML.
              <br />
              <b>Grid Positions</b> with a found UPC are{" "}
              <span style={{ color: "blue" }}>Blue.</span>
              <br />
              <b>Grid Positions</b> with a missing UPC are{" "}
              <span style={{ color: "deeppink" }}>Pink.</span>
              <br />
              <b>ML Positions</b> are{" "}
              <span style={{ color: "lightgreen" }}>Green.</span>
            </>
          }
        >
          <Button
            onClick={() => {
              drawRects("composite");
              setSelectedMode("composite");
            }}
            startIcon={<SelectAll />}
            variant={selectedMode === "composite" ? "contained" : "outlined"}
          >
            All Positions
          </Button>
        </Tooltip>

        <Tooltip
          title={
            <>
              Shows <span style={{ color: "orange" }}> orange</span> overlays to
              indicate <b>object detection areas</b> used by the iOS
              application. Green rectangles show product{" "}
              <span style={{ color: "lightgreen" }}>
                <b>ML Boxes</b>
              </span>{" "}
              .
            </>
          }
        >
          <Button
            onClick={() => {
              drawRects("detection");
              setSelectedMode("detection");
            }}
            startIcon={<PhotoSizeSelectLargeRounded />}
            variant={selectedMode === "detection" ? "contained" : "outlined"}
          >
            Detection Area
          </Button>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

export default CaptureBayItemCroppingDialog;
