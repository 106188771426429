import axiosInstance from "../axiosInstanceConfig";

// Fetches a .CSV report which gives a rundown on training data currently in use.
const getAdminTrainingReport = async () => {
  try {
    const response = await axiosInstance.get<string>(
      `trainingimage/AdminTrainingReport`
    );

    return response.data;
  } catch (error) {
    throw new Error(
      "There was an error generating a training report. Please contact support."
    );
  }
};

export default getAdminTrainingReport;
