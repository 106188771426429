import axiosInstance from "../axiosInstanceConfig";

export interface MyTeamPlanogram {
  PlanogramId: string;
  PlanogramName: string;
  PlanogramDisplayName: string;
  CreatedAt: string;
  LastModified: string;
}

const getMyTeamPlanograms = async (teamId: string) => {
  const params = new URLSearchParams();
  params.append("teamId", teamId);

  const response = await axiosInstance.get<MyTeamPlanogram[]>(
    `planogram/MyTeamPlanograms?${params.toString()}`
  );

  return response.data;
};

export default getMyTeamPlanograms;
