import { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Link,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";
import postAdminCreatePlanogram, {
  convertFormDataToCreateRequestData,
} from "../../../api/planograms/postAdminCreatePlanogram";
import AdminPlanogramEditFormikWrapper, {
  PlanogramFormData,
} from "./AdminPlanogramEditFormikWrapper";
import PlanogramXmlUploader from "./components/PlanogramXmlUploader";
import { australianStates } from "../../planograms/utils/strings";
import postAdminUploadPlanogramRevision from "../../../api/planograms/postAdminUploadPlanogramRevision";
import StoreAssignmentStep from "./components/StoreAssignmentStep";
import { InfoRounded, SkipNextRounded } from "@mui/icons-material";
import postAdminAssignPlanogramToStore from "../../../api/planograms/postAdminAssignPlanogramToStore";
import { blue } from "@mui/material/colors";
import { getLayoutNames } from "../../../api/layouts/getLayoutNames";
import upsertLayout from "../../../api/layouts/upsertLayoutName";

interface AdminCreatePlanogramDialogProp {
  open: boolean;
  refreshPlanograms: () => void;
  onClose: (_newId: string | undefined) => void;
}

/**
 * Dialog for creating a new planogram.
 * This dialog coordinates a 3-step process:
 * 1. Upload the XML file.
 * 2. Confirm the planogram details - pre-filling details from the XML import where possible.
 * 3. Assign the planogram to stores.
 * @param open - Whether the dialog is open.
 * @param onClose - Callback function to be called when the dialog is closed.
 */
const AdminCreatePlanogramDialog: React.FC<AdminCreatePlanogramDialogProp> = ({
  open,
  refreshPlanograms,
  onClose,
}) => {
  // State
  const [activeStep, setActiveStep] = useState(0);
  const [xmlFile, setXmlFile] = useState<File | null>(null);
  const [initialFormData, setInitialFormData] =
    useState<PlanogramFormData | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newPlanogramId, setNewPlanogramId] = useState<string | null>(null);
  const [newRevisionId, setNewRevisionId] = useState<string | null>(null);
  const [selectedStores, setSelectedStores] = useState<Set<string>>(new Set());
  const [isAssigning, setIsAssigning] = useState(false);
  const [duplicatePlanogramId, setDuplicatePlanogramId] = useState<string | null>(null);

  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // Functions
  const parseXml = async (xmlString: string): Promise<PlanogramFormData> => {
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(xmlString, "text/xml");

    const getElementValue = (
      tagName: string,
      parentElement: Element | Document = xmlDoc
    ): string | null => {
      // First try with exact tag name
      let element = parentElement.querySelector(tagName);

      // If not found and tagName contains periods, try replacing with underscore
      if (!element && tagName.includes(".")) {
        const alternativeTagName = tagName.replace(".", "_");
        element = parentElement.querySelector(alternativeTagName);
      }

      // If still not found, try a case-insensitive search
      if (!element) {
        element = Array.from(parentElement.getElementsByTagName("*")).find(
          (el) => el.tagName.toLowerCase() === tagName.toLowerCase()
        ) as Element | null;
      }

      return element ? element.textContent : null;
    };

    // Most data is nested in the <Planogram> tag.
    const planogramElement = xmlDoc.querySelector("Planogram");

    // Prefill the form with the data we have parsed from the XML.
    const formData: PlanogramFormData = {
      Name: getElementValue("Name", planogramElement ?? undefined) || "",
      Size:
        getElementValue("Number_of_Segments", planogramElement ?? undefined) ||
        "",
      FixtureHeight:
        getElementValue("Height", planogramElement ?? undefined) || "",
      FixtureDepth:
        getElementValue("Depth", planogramElement ?? undefined) || "",
      State: australianStates.find((state) =>
        getElementValue("Name", planogramElement ?? undefined)?.includes(
          state.value
        )
      )?.value,
      DisplayName:
        getElementValue("Abbrev_name", planogramElement ?? undefined) ||
        getElementValue("Abbrev._name", planogramElement ?? undefined) ||
        "",
      RightToLeft:
        getElementValue("Traffic_flow", planogramElement ?? undefined) ===
        "Left-right"
          ? "0"
          : "1",
      LayoutNameId: "",
      PlanogramCategoryId: "",
      FixtureTypeId: "",
    };

    return formData;
  };

  const handleXmlUpload = async (file: File | null) => {
    if (!file) return;
    setXmlFile(file);
    try {
      const xmlContent = await file.text();
      const parsedData = await parseXml(xmlContent);
      setInitialFormData(parsedData);
      setActiveStep(1);
    } catch (error) {
      console.error("Error parsing XML:", error);
      enqueueSnackbar(
        "Failed to parse XML file. Please check the file format.",
        { variant: "error" }
      );
    }
  };

  // Upload the XML file as a revision
  const uploadNewPlanogramRevision = async (
    planogramID: string,
    file: File
  ) => {
    const data = new FormData();
    data.append("file", file);

    try {
      const newRevisionId = await postAdminUploadPlanogramRevision(
        planogramID,
        data
      );
      setNewRevisionId(newRevisionId);
    } catch (err: any) {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Planogram Revision upload failed.",
      });
      console.log(err.message);
    }
  };

  const handleSubmit = async (values: PlanogramFormData) => {
    setIsSubmitting(true);
    try {
      const requestData = convertFormDataToCreateRequestData(values);

      // Create the Planogram
      const createdPlanogramId = await postAdminCreatePlanogram(requestData);

      // Upload the XML file as a revision after the planogram is created.
      if (xmlFile) {
        await uploadNewPlanogramRevision(createdPlanogramId, xmlFile);
      }

      // Refresh the Planogram list after uploads.
      refreshPlanograms();

      setNewPlanogramId(createdPlanogramId);
      enqueueSnackbar("Planogram created successfully", { variant: "success" });
      setActiveStep(2); // Move to the store assignment step
    } catch (error: any) {
      console.error("Failed to create planogram:", error);
      
      // Check if it's a duplicate planogram error
      if (error.response?.data?.detail?.includes("A duplicate planogram was found with ID:")) {
        const id = error.response.data.detail.split("ID: ")[1];
        setDuplicatePlanogramId(id);
      } else {
        enqueueSnackbar("Failed to create planogram", { variant: "error" });
      }
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = () => {
    setActiveStep(0);
    setXmlFile(null);
    setInitialFormData(null);
    onClose(undefined);
  };

  const handleAssignToStores = async () => {
    if (!newRevisionId) return;
    setIsAssigning(true);
    try {
      const assignmentPromises = Array.from(selectedStores).map((storeId) =>
        postAdminAssignPlanogramToStore({
          PlanogramRevisionId: newRevisionId,
          StoreId: storeId,
          Notes: "",
          Direction: false,
          Deactivated: false,
        })
      );

      const results = await Promise.allSettled(assignmentPromises);

      const successCount = results.filter(
        (result) => result.status === "fulfilled"
      ).length;
      const failureCount = results.length - successCount;

      if (failureCount === 0) {
        enqueueSnackbar("All assignments completed successfully.", {
          variant: "success",
        });
      } else if (successCount === 0) {
        enqueueSnackbar("All assignments failed. Please contact support.", {
          variant: "error",
        });
      } else {
        enqueueSnackbar(
          `Partial success: ${successCount} assigned, ${failureCount} failed.`,
          { variant: "warning" }
        );
      }
    } catch (error) {
      console.error("Error during bulk assignment:", error);
      enqueueSnackbar("An error occurred during bulk assignment", {
        variant: "error",
      });
    } finally {
      setIsAssigning(false);
      onClose(newPlanogramId ?? undefined);
    }
  };

  const handleDuplicateDialogClose = () => {
    setDuplicatePlanogramId(null);
  };

  const handleViewDuplicatePlanogram = () => {
    if (duplicatePlanogramId) {
      navigate(`/admin/planograms/edit?planogram=${duplicatePlanogramId}`);
      handleClose();
    }
  };

  const renderStepContent = () => {
    switch (activeStep) {
      case 0:
        return (
          <Stack direction="column" spacing={2}>
            {/* Big warning about what this dialog is for. */}
            <Stack
              direction="column"
              spacing={1}
              sx={{
                p: 4,
                borderRadius: 2,
                bgcolor: blue[50],
              }}
            >
              <Stack direction="row" spacing={1}>
                <InfoRounded color="info" />
                <Typography variant="body1" fontWeight={500}>
                  This is for new Planograms only
                </Typography>
              </Stack>
              <Typography variant="caption" color="text.secondary">
                If you are wanting to upload a new <b>revision</b> of an
                existing planogram, please close this window and click the name
                of the planogram in the list.
              </Typography>
            </Stack>
            {/* Uploader */}
            <PlanogramXmlUploader onFileUpload={handleXmlUpload} />
          </Stack>
        );
      case 1:
        return (
          <AdminPlanogramEditFormikWrapper
            initialValues={initialFormData ?? undefined}
            handleSubmit={handleSubmit}
            isEditMode={true}
            onDisabledChanged={() => {}}
            displayHelperText={true}
          />
        );
      case 2:
        return newPlanogramId ? (
          <StoreAssignmentStep
            revisionId={newRevisionId ?? ""}
            onClose={() => {}}
            selectedStores={selectedStores}
            setSelectedStores={setSelectedStores}
          />
        ) : null;
      default:
        return null;
    }
  };

  const steps = ["Upload XML", "Confirm Details", "Assign Stores"];

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>Create New Planogram</DialogTitle>
        <DialogContent>
          <Stepper activeStep={activeStep} sx={{ mb: 6 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {renderStepContent()}
        </DialogContent>
        <DialogActions>
          {activeStep !== 2 && (
            <Button color="error" onClick={handleClose}>
              Cancel
            </Button>
          )}
          {activeStep === 1 && (
            <LoadingButton
              loading={isSubmitting}
              variant="contained"
              type="submit"
              form="planogram-form"
            >
              Create Planogram
            </LoadingButton>
          )}
          {activeStep === 2 && (
            <Button startIcon={<SkipNextRounded />} onClick={handleClose}>
              Skip
            </Button>
          )}
          {activeStep === 2 && (
            <LoadingButton
              variant="contained"
              disabled={selectedStores.size === 0}
              onClick={handleAssignToStores}
              loading={isAssigning}
            >
              Confirm
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>

      {/* Duplicate Planogram Dialog */}
      <Dialog 
        open={!!duplicatePlanogramId} 
        onClose={handleDuplicateDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Duplicate Planogram Found</DialogTitle>
        <DialogContent>
          <Typography>
            A planogram with these details already exists. Would you like to update that Planogram instead?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDuplicateDialogClose}>Cancel</Button>
          <Button variant="contained" onClick={handleViewDuplicatePlanogram}>
            View Planogram
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminCreatePlanogramDialog;
