import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Tab,
  Tabs,
  Typography,
  Checkbox,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  AddCircleOutline,
  ArrowBackRounded,
  ArrowForwardRounded,
  EditOutlined,
} from "@mui/icons-material";
import { StoreFormInitialValues } from "./types/StoreFormInitialValues";
import URMapkitProvider from "../../dashboard/components/URMapkitProvider";
import StoreLocation from "../../../components/StoreLocation/StoreLocation";
import StoreLocationCard from "./components/AdminStoreLocationCard";
import { StoreDetails } from "./types/StoreDetails";
import { urStorePalette } from "../../../themes/urStoreTheme";
import getStore from "../../../api/stores/getStore";
import postAdminEnablePlanogramAtStore from "../../../api/planograms/postAdminEnablePlanogramAtStore";
import postAdminUpdateStore, {
  AdminUpdateStoreRequestData,
} from "../../../api/stores/postAdminUpdateStore";
import { PlanogramAssignMenu } from "./components/PlanogramAssignMenu";
import { PlanogramAssignment } from "../../../models/planograms/CaptureTypes";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import useLayoutStore from "../../../stores/layout/LayoutStore";
import AdminStoreFormDialog from "./components/AdminStoreFormDialog";
import { useSnackbar } from "notistack";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import getStoreCategories from "../../../api/store-categories/getStoreCategoriesForStore";
import { StoreCategory } from "./types/CategoryTypes";
import StoreCategoryDialog from "./components/StoreCategoryDialog";
import getFixtures, { FixtureType } from "../../../api/fixtures/getFixtures";
import {
  getLayoutNames,
  PlanogramLayout,
} from "../../../api/layouts/getLayoutNames";

interface StoreCategoryColumns extends StoreCategory {
  Actions?: null;
}

const AdminStoresEditPage = (props: any) => {
  // Util
  const navigate = useNavigate();
  const { setSubRoute } = useLayoutStore();
  const { enqueueSnackbar } = useSnackbar();

  // Content State
  const [storeId, setStoreId] = useState<string | null>(null);
  const [planogramAssignments, setPlanogramAssignments] = useState<
    PlanogramAssignment[]
  >([]);
  const [formValues, setFormValues] = useState<StoreFormInitialValues | null>(
    null
  );
  const [storeData, setStoreData] = useState<StoreDetails | null>(null);
  const [storeCategories, setStoreCategories] = useState<StoreCategory[]>([]);
  const [planogramLayouts, setPlanogramLayouts] = useState<PlanogramLayout[]>(
    []
  );
  const [editStoreCategory, setEditStoreCategory] =
    useState<StoreCategory | null>(null);
  const [fixtureTypes, setFixtureTypes] = useState<FixtureType[]>([]);

  // Meta State
  const [params, setParams] = useSearchParams();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [storeCategoriesLoading, setStoreCategoriesLoading] =
    useState<boolean>(false);
  const [isRequestLoading, setIsRequestLoading] = useState<boolean>(false);
  const [planoAssignMenuOpen, setPlanoAssignMenuOpen] =
    useState<boolean>(false);
  const [categoryDialogOpen, setCategoryDialogOpen] = useState<boolean>(false);
  const [planoAssignAnchor, setPlanoAssignAnchor] =
    useState<null | HTMLElement>(null);
  const [activeTab, setActiveTab] = useState<number>(0);

  // Constants
  const storeLongitude = formValues?.longitude;
  const storeLatitude = formValues?.latitude;
  const teamId = props.selectedTeam;

  useEffect(() => {
    let storeID = params.get("store");
    if (storeID) {
      setStoreId(params.get("store"));
    } else {
      navigate("../admin/stores");
    }
  }, []);

  useEffect(() => {
    fetchStorePlanograms();
    fetchStoreCategories();
    fetchPlanogramLayouts();
    fetchFixtureTypes();
  }, [teamId, storeId]);

  const fetchPlanogramLayouts = async () => {
    try {
      const data = await getLayoutNames();
      setPlanogramLayouts(data);
    } catch {
      enqueueSnackbar("Failed to load Layout Data.", { variant: "error" });
    }
  };

  const fetchStoreCategories = async () => {
    if (storeId)
      try {
        setStoreCategoriesLoading(true);
        const data = await getStoreCategories(storeId);
        setStoreCategories(data);
      } catch {
        enqueueSnackbar("Failed to load Store Categories.", {
          variant: "error",
        });
      } finally {
        setStoreCategoriesLoading(false);
      }
  };

  const fetchStorePlanograms = async () => {
    if (!storeId) {
      return;
    }
    if (!teamId) {
      return;
    }
    try {
      const data = await getStore(storeId);
      if (data) {
        setStoreData(data);
        composeEditFormData(data);
        data.PlanogramAssignments
          ? setPlanogramAssignments(data.PlanogramAssignments)
          : setPlanogramAssignments([]);
        setIsLoading(false);
        setSubRoute({ title: data.Name });
      }
    } catch (err: any) {
      setIsLoading(false);
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Failed to fetch store planograms.",
      });
      console.log(err.message);
    }
  };

  const fetchFixtureTypes = async () => {
    try {
      const data = await getFixtures();
      setFixtureTypes(data);
    } catch {
      enqueueSnackbar("Failed to load Fixture Types.", { variant: "error" });
    }
  };

  function composeEditFormData(storeData: StoreDetails) {
    const values = {
      name: storeData.Name,
      address: storeData.Address,
      addressLine2: storeData.AddressLine2,
      latitude: storeData.Latitude,
      longitude: storeData.Longitude,
      city: storeData.City,
      suburb: storeData.Suburb,
      country: storeData.Country,
      state: storeData.State,
      postCode: storeData.Postcode,
    };

    setFormValues(values);
  }

  const togglePlanogramAssignment = async (
    planogramId: string,
    deactivated: boolean
  ) => {
    try {
      const response = await postAdminEnablePlanogramAtStore(
        planogramId,
        deactivated
      );
      if (response) {
        fetchStorePlanograms();
        enqueueSnackbar("Assignment Updated", {
          variant: "success",
        });
      }
    } catch (error) {
      enqueueSnackbar("Failed to add assignment.", {
        variant: "error",
      });
      throw new Error("Failed to update planogram assignment.");
    }
  };

  const handleStoreEdit = async (editedFormValues: StoreFormInitialValues) => {
    setIsRequestLoading(true);
    if (storeId) {
      const requestData: AdminUpdateStoreRequestData = {
        StoreId: storeId,
        Name: editedFormValues.name,
        Address: editedFormValues.address,
        AddressLine2: editedFormValues.addressLine2,
        Latitude: editedFormValues.latitude ? editedFormValues.latitude : 0,
        Longitude: editedFormValues.longitude ? editedFormValues.longitude : 0,
        City: editedFormValues.city,
        Suburb: editedFormValues.suburb,
        State:
          editedFormValues.country === "New Zealand"
            ? null
            : editedFormValues.state,
        Country: editedFormValues.country,
        Postcode: editedFormValues.postCode,
      };

      try {
        const data = await postAdminUpdateStore(requestData);
        if (data) {
          setIsRequestLoading(false);
          setIsEditModalOpen(false);
          fetchStorePlanograms();
          enqueueSnackbar("Success", {
            variant: "success",
            cta: "Successfully edited store details.",
          });
        }
      } catch (error) {
        setIsRequestLoading(false);
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Failed to edit store details.",
        });
        console.error("Error editing store:", error);
      }
    }
  };

  return (
    <>
      {/* Modal City - Population: 3 */}
      <PlanogramAssignMenu
        storeId={storeId ?? ""}
        open={planoAssignMenuOpen}
        handleClose={() => setPlanoAssignMenuOpen(false)}
        handleAssign={() => fetchStorePlanograms()}
        currentlyAssigned={planogramAssignments.map(
          (pa) => pa.PlanogramRevisionId
        )}
      />

      <AdminStoreFormDialog
        mode="edit"
        formValues={formValues!}
        handleSubmit={handleStoreEdit}
        open={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}
        loading={isRequestLoading}
      />

      <StoreCategoryDialog
        inUseLayouts={storeCategories.map((sc) => sc.LayoutNameId)}
        storeId={storeId!}
        open={categoryDialogOpen}
        handleClose={() => {
          setCategoryDialogOpen(false);
          setEditStoreCategory(null);
        }}
        handleSubmit={async () => {
          setEditStoreCategory(null);
          await fetchStoreCategories();
        }}
        existingCategory={editStoreCategory}
      />

      {/* Primary Content */}
      {isLoading ? (
        <Backdrop
          sx={{
            color: urStorePalette.greys.lightest,
            zIndex: 100,
            mt: 0,
            position: "absolute",
          }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : (
        <>
          <Box sx={ResponsiveContainerStyle}>
            {/* Header */}
            <Stack
              direction="row"
              justifyContent={"space-between"}
              alignItems={"center"}
              sx={{ w: "100%", mb: 4 }}
            >
              <Typography variant="h6">{storeData?.Name}</Typography>
              <Button
                sx={{ mr: 1 }}
                variant="contained"
                startIcon={<EditOutlined />}
                onClick={() => setIsEditModalOpen(true)}
              >
                Edit Store Details
              </Button>
            </Stack>

            {/* Address & Map Location */}
            <Stack direction={"row"} gap={2} mb={2}>
              <Card>
                <CardContent>
                  <Stack direction="column" gap={2} sx={{ w: "100%" }}>
                    <Typography variant="h6">Address</Typography>
                    <Divider />
                    <Typography variant="subtitle2">
                      {storeData?.Address || "-"}, {storeData?.Suburb},{" "}
                      {storeData?.City || "-"}, {storeData?.Postcode || "-"}
                    </Typography>
                    <Typography variant="subtitle2">
                      {storeData?.Country === "Australia"
                        ? `${storeData?.State || "-"}, ${storeData?.Country}`
                        : `${storeData?.Country}`}
                    </Typography>
                    <Typography variant="subtitle2">
                      {storeData?.AddressLine2}
                    </Typography>

                    <Stack direction="row" gap={2} sx={{ mt: "auto" }}>
                      <Chip
                        size="small"
                        label={
                          <div>
                            Lat: <b>{storeData?.Latitude || "-"}</b>
                          </div>
                        }
                      />
                      <Chip
                        size="small"
                        label={
                          <>
                            Lng: <b>{storeData?.Longitude || "-"}</b>
                          </>
                        }
                      />
                    </Stack>
                  </Stack>
                </CardContent>
              </Card>

              <StoreLocationCard
                sxStyle={{
                  minHeight: "200px",
                  width: "100%",
                }}
                contentStyle={{
                  p: 0,
                  "&:last-of-type": { pb: 0 },
                }}
              >
                <URMapkitProvider>
                  <StoreLocation
                    longitude={storeLongitude ?? 0}
                    latitude={storeLatitude ?? 0}
                  />
                </URMapkitProvider>
              </StoreLocationCard>
            </Stack>

            {/* Tab Selector */}
            <Tabs value={activeTab} sx={{ mb: 1 }}>
              <Tab
                value={0}
                onClick={() => setActiveTab(0)}
                label={"Store Layouts"}
              />
              <Tab
                value={1}
                onClick={() => setActiveTab(1)}
                label={"Planogram Assignments"}
              />
            </Tabs>

            {/* Store Categories Table */}
            {activeTab === 0 && (
              <Paper sx={{ p: 4 }}>
                <Stack gap={4}>
                  <AgGridWrapper
                    id="store-categories"
                    rowData={storeCategories as StoreCategoryColumns[]}
                    loading={storeCategoriesLoading}
                    columnDefs={[
                      {
                        field: "LayoutNameId",
                        headerName: "Layout Name",
                        cellRenderer: (_data: any) =>
                          planogramLayouts.find(
                            (_layout) => _layout.LayoutNameId === _data.value
                          )?.Name,
                      },
                      { field: "BayCount" },

                      { field: "FixtureDepth" },
                      { field: "FixtureHeight" },
                      {
                        field: "FixtureTypeId",
                        headerName: "Fixture Name",
                        cellRenderer: (params: any) => {
                          const fixtureType = fixtureTypes.find(
                            (ft) =>
                              ft.FixtureTypeId === params.data.FixtureTypeId
                          );
                          return fixtureType ? fixtureType.Name : "-";
                        },
                      },
                      {
                        field: "FixtureTypeId",
                        headerName: "Fixture Type",
                        cellRenderer: (params: any) => {
                          const fixtureType = fixtureTypes.find(
                            (ft) => ft.FixtureTypeId === params.value
                          );
                          return fixtureType ? fixtureType.Type : "-";
                        },
                      },
                      {
                        field: "RightToLeft",
                        headerName: "Direction",
                        cellRenderer: (params: any) => {
                          return params.value ? (
                            <Chip
                              size="small"
                              color="error"
                              icon={<ArrowBackRounded />}
                              label="Right to Left"
                            />
                          ) : (
                            <Chip
                              size="small"
                              color="info"
                              icon={<ArrowForwardRounded />}
                              label="Left to Right"
                            />
                          );
                        },
                      },
                      {
                        field: "Actions",
                        cellRenderer: (row: any) => (
                          <Button
                            variant="contained"
                            size="small"
                            onClick={() => {
                              setEditStoreCategory(
                                storeCategories.find(
                                  (sc) =>
                                    sc.LayoutNameId === row.data.LayoutNameId
                                )!
                              );
                              setCategoryDialogOpen(true);
                            }}
                          >
                            Edit
                          </Button>
                        ),
                      },
                    ]}
                    height="38vh"
                    quickSearch
                    plugins={
                      <Button
                        variant="contained"
                        onClick={() => {
                          setCategoryDialogOpen(true);
                        }}
                        startIcon={<AddCircleOutline />}
                      >
                        New Store Layout
                      </Button>
                    }
                  />
                </Stack>
              </Paper>
            )}

            {/* Assignments Table */}
            {activeTab === 1 && (
              <Paper sx={{ p: 4 }}>
                <Stack gap={4}>
                  <AgGridWrapper
                    id="plano-assignments"
                    rowData={planogramAssignments}
                    columnDefs={[
                      { field: "Name", flex: 2.5 },
                      {
                        field: "CreatedAt",
                        headerName: "Assigned On",
                        cellRenderer: (data: any) =>
                          formatDisplayDate(data.value),
                        flex: 1,
                      },
                      {
                        field: "PlanogramRevisionCreatedAt",
                        headerName: "Revision Creation Date",
                        cellRenderer: (data: any) =>
                          data.value ? formatDisplayDate(data.value) : "-",
                        flex: 1,
                      },
                      {
                        field: "Deactivated",
                        headerName: "Active",
                        flex: 1,
                        cellRenderer: (row: any) => (
                          <Box sx={{ width: "100%" }} alignItems="center">
                            <Checkbox
                              checked={!row.value}
                              onClick={() =>
                                togglePlanogramAssignment(
                                  row.data.PlanogramAssignmentId,
                                  !row.value
                                )
                              }
                            />
                          </Box>
                        ),
                      },
                    ]}
                    height="38vh"
                    quickSearch
                    plugins={
                      <Button
                        variant="contained"
                        onClick={(e) => {
                          setPlanoAssignMenuOpen(true);
                          setPlanoAssignAnchor(e.currentTarget);
                        }}
                        startIcon={<AddCircleOutline />}
                      >
                        Assign Planogram to Store
                      </Button>
                    }
                  />
                </Stack>
              </Paper>
            )}
          </Box>
        </>
      )}
    </>
  );
};

export default AdminStoresEditPage;
