import {
  Alert,
  AlertTitle,
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grow,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { StoreCategory } from "../types/CategoryTypes";
import { Close, ErrorRounded, InfoRounded } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import upsertStoreCategory from "../../../../api/store-categories/upsertStoreCategory";
import getFixtures, { FixtureType } from "../../../../api/fixtures/getFixtures";
import { grey } from "@mui/material/colors";
import {
  getLayoutNames,
  PlanogramLayout,
} from "../../../../api/layouts/getLayoutNames";

export default function StoreCategoryDialog(props: {
  open: boolean;
  storeId: string;
  existingCategory: StoreCategory | null;
  inUseLayouts: string[];
  handleClose: () => void;
  handleSubmit: () => void;
}) {
  // State
  const [form, setForm] = useState<Partial<StoreCategory>>(
    props.existingCategory ?? {}
  );

  const [fixtureTypes, setFixtureTypes] = useState<FixtureType[]>([]);
  const [layoutNames, setLayoutNames] = useState<PlanogramLayout[]>([]);
  const [layoutInUseWarning, setLayoutInUseWarning] = useState<boolean>(false);

  // Effects
  useEffect(() => {
    const fetchLayoutNames = async () => {
      try {
        const data = await getLayoutNames();
        setLayoutNames(data);
      } catch {
        enqueueSnackbar("Failed to load Layout Names.", { variant: "error" });
      }
    };

    const fetchFixtureTypes = async () => {
      try {
        const data = await getFixtures();
        setFixtureTypes(data);
      } catch {
        enqueueSnackbar("Error", {
          variant: "error",
          cta: "Failed to load Fixture Types.",
        });
      }
    };

    fetchFixtureTypes();
    fetchLayoutNames();
  }, []);

  // Handles resetting form data if the edit form changes.
  useEffect(() => {
    if (props.existingCategory) {
      setForm(props.existingCategory);
    } else {
      setForm({});
    }
  }, [props.existingCategory]);

  useEffect(() => {
    if (
      props.inUseLayouts.includes(form.LayoutNameId ?? "") &&
      !props.existingCategory
    ) {
      setLayoutInUseWarning(true);
    } else {
      setLayoutInUseWarning(false);
    }
  }, [form.LayoutNameId, props.existingCategory, props.inUseLayouts]);

  // Check all forms are filled.
  const isFormValid = () => {
    return (
      form.BayCount &&
      // TODO tidy up the form validation.
      form.RightToLeft !== null && // Different check for this one since its a bool value.
      form.FixtureDepth &&
      form.FixtureHeight &&
      form.FixtureTypeId &&
      form.LayoutNameId
    );
  };

  // Handles submission.
  async function handleFormSubmit() {
    try {
      // Coerce the current form data back out of being a Partial<T>.
      const success = await upsertStoreCategory({
        ...form,
        StoreId: props.storeId,
      } as StoreCategory);

      if (success) {
        // Fire the prop submit handler. This is usually to refetch data.
        props.handleSubmit();

        // Snacky
        enqueueSnackbar("Changes Saved", { variant: "success" });

        // Close
        handleFormClose();
      }
    } catch {
      enqueueSnackbar("Failed to save Store Layout.", { variant: "error" });
    }
  }

  function handleFormClose() {
    setForm({});
    props.handleClose();
  }

  return (
    <Dialog open={props.open} maxWidth={"sm"} onClose={handleFormClose}>
      <DialogTitle>
        {!props.existingCategory ? "New Store Layout" : "Edit Store Layout"}
      </DialogTitle>
      <DialogContent>
        {/* Explanation */}
        <Alert
          color="info"
          sx={{ border: "none", mb: 2 }}
          icon={<InfoRounded />}
        >
          <AlertTitle>Usage</AlertTitle>
          Store Layouts define rules for which Planograms can be assigned or suggested to this
          store. Updating layouts may make prior assignments invalid.
        </Alert>

        {/* Error (in use category) */}
        <Grow in={layoutInUseWarning} unmountOnExit>
          <Alert
            color="error"
            sx={{ border: "none", mb: 2 }}
            icon={<ErrorRounded />}
          >
            <AlertTitle>Layout In Use</AlertTitle>
            Shelf metrics are already configured for this Layout. To edit the
            existing shelf metrics, go back and select this layout from the
            list of store layouts.
          </Alert>
        </Grow>

        {/* Form Stack */}
        <Stack direction="column" gap={2}>
          {/* Planogram Layout */}
          <Autocomplete
            loading={!layoutNames}
            loadingText={"Loading..."}
            disabled={Boolean(props.existingCategory)}
            value={
              layoutNames.find((ln) => ln.LayoutNameId === form.LayoutNameId) ??
              null
            }
            options={layoutNames ?? []}
            onChange={(_e, _value) => {
              setForm((prev) => ({
                ...prev,
                LayoutNameId: _value ? _value.LayoutNameId : undefined,
              }));
            }}
            getOptionLabel={(option) => option.Name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Planogram Layout"
                variant="outlined"
                size="small"
              />
            )}
          />

          {/* Bay Count */}
          <TextField
            type="number"
            onChange={(e) =>
              setForm((prev) => ({ ...prev, BayCount: Number(e.target.value) }))
            }
            variant="outlined"
            size="small"
            label="Bay Count"
            value={form.BayCount ?? ""}
          />

          {/* Fixture Depth */}
          <Typography variant="caption">Fixure Detail</Typography>
          <FormControl size="small">
            <InputLabel>Fixture Type</InputLabel>
            <Select
              value={form.FixtureTypeId || ""}
              size="small"
              placeholder="Fixture Type"
              label="Fixture Type"
              onChange={(e) =>
                setForm((prev) => ({ ...prev, FixtureTypeId: e.target.value }))
              }
            >
              {fixtureTypes.map((fixture) => (
                <MenuItem
                  key={fixture.FixtureTypeId}
                  value={fixture.FixtureTypeId}
                >
                  {fixture.Name}{" "}
                  <span
                    style={{ color: grey[500], fontSize: 14, paddingLeft: 8 }}
                  >{`${fixture.Type}`}</span>
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            type="number"
            onChange={(e) =>
              setForm((prev) => ({
                ...prev,
                FixtureDepth: e.target.value,
              }))
            }
            variant="outlined"
            size="small"
            label="Fixture Depth"
            value={form.FixtureDepth ?? ""}
          />

          {/* Fixture Height */}
          <TextField
            type="number"
            onChange={(e) =>
              setForm((prev) => ({
                ...prev,
                FixtureHeight: e.target.value,
              }))
            }
            variant="outlined"
            size="small"
            label="Fixture Height"
            value={form.FixtureHeight ?? ""}
          />

          {/* Direction Flow */}
          <Typography variant="caption" sx={{ mb: -2 }}>
            Direction
          </Typography>

          <RadioGroup
            row
            name="direction"
            value={form.RightToLeft ?? false}
            onChange={(e) =>
              setForm((prev) => ({
                ...prev,
                RightToLeft: e.target.value === "true",
              }))
            }
          >
            <FormControlLabel
              value={false}
              control={<Radio size="small" checked={!form.RightToLeft} />}
              label="Left to Right"
            />
            <FormControlLabel
              value={true}
              control={<Radio size="small" />}
              label="Right to Left"
            />
          </RadioGroup>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ px: 6, pb: 4 }}>
        <Button
          onClick={() => props.handleClose()}
          color="error"
          startIcon={<Close />}
        >
          Cancel
        </Button>
        <Button
          disabled={
            !isFormValid() || (layoutInUseWarning && !props.existingCategory)
          }
          onClick={handleFormSubmit}
          variant="contained"
        >
          {props.existingCategory ? "Save" : "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
