import {
  Box,
  Button,
  Chip,
  Stack,
  Autocomplete,
  TextField,
  Typography,
  ListItem,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  CameraRounded,
  CheckCircleRounded,
  Circle,
  FastForwardOutlined,
  FastRewindOutlined,
  UploadFileOutlined,
} from "@mui/icons-material";
import { AgGridWrapper } from "../../../components/AgGridWrapper/AgGridWrapper";
import { formatDisplayDate } from "../../../components/AgGridWrapper/utilities";
import getPlanogramList from "../../../api/planograms/getPlanogramsList";
import { SnapshotUploader } from "../components/SnapshotUploader";
import { ColDef } from "ag-grid-community";
import { DisplayCapture } from "../hooks/usePaginatedCaptureData";
import { ResponsiveContainerStyle } from "../../../helpers/generalUtilities";
import { useSnackbar } from "notistack";
import { CheckAdminAuth } from "../../authentication/contexts/AuthContext";
import ActionsMenu from "../components/ActionsMenu";
import { StoreData } from "../../stores/types/StoreResponseData";
import getStoresByTeam from "../../../api/teams/getStoresByTeam";
import getMyTeamPlanograms, {
  MyTeamPlanogram,
} from "../../../api/planograms/getMyTeamPlanograms";
import PopperMy from "../components/OverflowPopper";
interface DisplayCaptureColumn extends DisplayCapture {
  Actions?: null;
}

const PlanogramsListPage = (props: any) => {
  // Util
  const navigate = useNavigate();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  // State
  const teamID = props.selectedTeam || null;
  const [allCaptures, setAllCaptures] = useState<any[]>([]);
  const [page, setPage] = useState<number>(() => {
    const searchParams = new URLSearchParams(location.search);
    return parseInt(searchParams.get("page") || "0", 10);
  });
  const [snapshotUploaderOpen, setSnapshotUploaderOpen] =
    useState<boolean>(false);
  const [totalCaptureCount, setTotalCaptureCount] = useState<number>(0);
  const [stores, setStores] = useState<StoreData[]>([]);
  const [selectedStore, setSelectedStore] = useState<StoreData | null>(null);
  const [planograms, setPlanograms] = useState<MyTeamPlanogram[]>([]);
  const [selectedPlanogram, setSelectedPlanogram] =
    useState<MyTeamPlanogram | null>(null);

  // Loading States
  const [loadingCaptures, setLoadingCaptures] = useState<boolean>(false);
  const [loadingStores, setLoadingStores] = useState(false);
  const [loadingPlanograms, setLoadingPlanograms] = useState(false);

  // Helper Functions
  const getAccuracyColour = (value: number) => {
    if (value * 100 < 50) return "warning";
    if (value * 100 >= 50) {
      if (value === 1) {
        return "info";
      } else {
        return "success";
      }
    }
  };

  // AG State
  const [colDefs] = useState<ColDef<DisplayCaptureColumn>[]>([
    {
      field: "PlanogramName",
      flex: 2,
      cellRenderer: (data: any) => {
        const statusLabel = getStatusLabel(data.data.Status);

        return (
          <Stack direction="column" alignItems="start" gap={1}>
            <Typography variant="body2" fontWeight={500}>
              {data.value}
            </Typography>

            <Stack direction="row" alignItems="center" gap={1}>
              <Chip
                icon={<Circle color={statusLabel.severity} />}
                label={statusLabel.label}
                size="small"
              />
              {CheckAdminAuth() && (
                <Chip
                  size="small"
                  label={`${(data.data.Accuracy * 100).toFixed(0)}%`}
                  icon={
                    <Circle color={getAccuracyColour(data.data.Accuracy)} />
                  }
                />
              )}
            </Stack>
          </Stack>
        );
      },
    },
    { field: "TaskName" },

    { field: "Store", flex: 1 },
    { field: "NumberOfBays", headerName: "Bays", flex: 0.5 },
    { field: "UserName", flex: 1 },

    {
      field: "CreatedAt",
      sort: "desc",
      headerName: "Capture Time",
      flex: 1,
      cellRenderer: (data: any) => (
        <Stack direction="column">
          <Typography
            variant="body2"
            display="flex"
            alignItems="center"
            gap={1}
          >
            <CameraRounded sx={{ fontSize: 16 }} />
            <span style={{ fontWeight: 500 }}>
              {formatDisplayDate(data.data.CreatedAt)}
            </span>
          </Typography>
          {data.data.TaskCompletedTime && (
            <Typography
              variant="body2"
              display="flex"
              alignItems="center"
              gap={1}
            >
              <CheckCircleRounded sx={{ fontSize: 16 }} color="success" />
              <span style={{ fontWeight: 500 }}>
                {formatDisplayDate(data.data.TaskCompletedTime)}
              </span>
            </Typography>
          )}
        </Stack>
      ),
    },

    {
      field: "Actions",

      sortable: false,
      flex: 1,
      cellRenderer: (row: any) => (
        <ActionsMenu
          rowData={row.data}
          handlePlanogramSelected={handlePlanogramSelected}
          fetchPlanograms={fetchCaptures}
        />
      ),
    },
  ]);

  // Add fetchStores function
  const fetchStores = async () => {
    if (!teamID) return;

    try {
      setLoadingStores(true);
      const data = await getStoresByTeam(teamID);
      setStores(data);
    } catch (error) {
      console.error("Error fetching stores:", error);
      enqueueSnackbar("Failed to load stores", { variant: "error" });
    } finally {
      setLoadingStores(false);
    }
  };

  // Add useEffect for fetching stores
  useEffect(() => {
    fetchStores();
  }, [teamID]);

  // Add fetchPlanograms function
  const fetchPlanogramsList = async () => {
    if (!teamID) return;

    try {
      setLoadingPlanograms(true);
      const data = await getMyTeamPlanograms(teamID);
      setPlanograms(data);
    } catch (error) {
      console.error("Error fetching planograms:", error);
      enqueueSnackbar("Failed to load planograms", { variant: "error" });
    } finally {
      setLoadingPlanograms(false);
    }
  };

  useEffect(() => {
    fetchPlanogramsList();
  }, [teamID]);

  async function fetchCaptures() {
    try {
      setLoadingCaptures(true);
      const data = await getPlanogramList(
        teamID,
        page,
        selectedStore?.StoreId,
        selectedPlanogram?.PlanogramId
      );

      // Update URL with all filters
      const searchParams = new URLSearchParams();
      searchParams.append("page", page.toString());
      if (selectedStore) searchParams.append("store", selectedStore.StoreId);
      if (selectedPlanogram)
        searchParams.append("planogram", selectedPlanogram.PlanogramId);

      navigate(`?${searchParams.toString()}`, { replace: true });

      if (data) {
        setAllCaptures(data.items);
        setTotalCaptureCount(data.total);
      }
    } catch {
      enqueueSnackbar("Error", {
        variant: "error",
        cta: "Planograms failed to load",
        preventDuplicate: true,
      });
      setAllCaptures([]);
    } finally {
      setLoadingCaptures(false);
    }
  }

  // Add effect to refetch when planogram selection changes
  useEffect(() => {
    fetchCaptures();
  }, [teamID, page, selectedStore, selectedPlanogram]);

  function handlePlanogramSelected(planogramId: string) {
    navigate(`captures?capture=${planogramId}`, { state: { page: page } });
  }

  function getStatusLabel(status: string): {
    label: string;
    severity: "error" | "success" | "warning";
  } {
    switch (status) {
      case "duplicate":
        return { label: "Duplicate", severity: "error" };
      case "outofdate":
        return { label: "Out of Date", severity: "error" };
      case "pending":
        return { label: "Pending", severity: "warning" };
      case "processing":
        return { label: "Processing", severity: "warning" };
      case "complete":
        return { label: "Complete", severity: "success" };
      default:
        return { label: "Unknown", severity: "error" };
    }
  }

  // Inside the component, add this calculation
  const totalPages = Math.ceil(totalCaptureCount / 10);

  // Add function to clear all filters
  const clearFilters = () => {
    setSelectedStore(null);
    setSelectedPlanogram(null);
    setPage(0);
  };

  return (
    <Box sx={ResponsiveContainerStyle}>
      {/* Add Snapshot Modal */}
      <SnapshotUploader
        open={snapshotUploaderOpen}
        handleClose={() => setSnapshotUploaderOpen(false)}
      />

      {/* Table */}
      <AgGridWrapper
        id="plano"
        rowHeight={70}
        columnDefs={colDefs}
        rowData={allCaptures}
        hideColumnSelector
        loading={loadingCaptures}
        rememberPageNumber
        onPaginationNext={() => setPage(page + 1)}
        onPaginationPrevious={() => setPage(page - 1)}
        paginationConfig={{
          currentPage: page,
          totalPages: totalPages,
          pageSize: 10,
          totalRows: totalCaptureCount,
          suppressPaginationPanel: true,
        }}
        plugins={
          <Stack direction="row" alignItems="center" gap={2}>
            <Stack direction="row" alignItems="center" gap={2} sx={{ flex: 1 }}>
              <Autocomplete
                sx={{ width: 200, "& fieldset": { borderRadius: 2 } }}
                options={stores}
                loading={loadingStores}
                value={selectedStore}
                onChange={(_, newValue) => {
                  setSelectedStore(newValue);
                  setPage(0);
                }}
                renderOption={(props, option) => (
                  <ListItem {...props}>
                    <Stack direction="column">
                      <Typography variant="body2" fontWeight={600}>
                        {option.Name}
                      </Typography>
                      <Typography variant="body2" fontWeight={400}>
                        {option.City}
                        {option.State && `, ${option.State}`}
                      </Typography>
                    </Stack>
                  </ListItem>
                )}
                getOptionLabel={(option) =>
                  `${option.Name} - ${option.City}, ${option.State}`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Store"
                    size="small"
                    placeholder="Select a store"
                  />
                )}
              />
              <Autocomplete
                PopperComponent={PopperMy}
                sx={{ width: 250, "& fieldset": { borderRadius: 2 } }}
                options={planograms}
                loading={loadingPlanograms}
                value={selectedPlanogram}
                onChange={(_, newValue) => {
                  setSelectedPlanogram(newValue);
                  setPage(0);
                }}
                renderOption={(props, option) => (
                  <ListItem {...props}>
                    <Stack direction="column">
                      <Typography variant="body2" fontWeight={600}>
                        {option.PlanogramDisplayName}
                      </Typography>
                      <Typography variant="body2" fontWeight={400}>
                        {option.PlanogramName}
                      </Typography>
                    </Stack>
                  </ListItem>
                )}
                getOptionLabel={(option) =>
                  `${option.PlanogramDisplayName} (${option.PlanogramName})`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Filter by Planogram"
                    size="small"
                    placeholder="Select a planogram"
                  />
                )}
              />
            </Stack>
            <Button
              variant="contained"
              onClick={() => setSnapshotUploaderOpen(true)}
              startIcon={<UploadFileOutlined />}
            >
              Upload Snapshot
            </Button>
          </Stack>
        }
      />
    </Box>
  );
};

export default PlanogramsListPage;
